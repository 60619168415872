import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import styles from "./home.css";
import { Link, NavLink, Outlet } from "react-router-dom";
import { MainCards } from "../mainCards/MainCards";
import { Project } from "../project/Project";
import cover from "../../Pics/background/bkw2.jpg";
import coverLogo from "../../Pics/Logos/LOGOClean.png";

export function Home() {  

  const numStyle = {
    color: "#d32026",
    letterSpacing: "1px",
    fontSize: "18px",
  };

  const specStyle = {
    letterSpacing: "0px",
    wordSpacing: "1px",
  };
 
  return (
    <div className="homeBody">
      {/*
          <div style={{backgroundImage: `url(${cover})`}} className='heroCoverDD'>
          </div>
        */}
      <div className="fullService">
        <h2 className="fullService1">
          <span>DAHAB-</span>
          <span>DESIGN</span> is a Full-Service Firm Specialized In{" "}
        </h2>
        <h2 className="fullService2" style={specStyle}>         
          Real-Estate - Architectural-Designs - Furniture{" "}
        </h2>
        <div style={{display:'flex', alignItems:'center'}}>
          <p>
            <span style={numStyle}>100+</span> Designed Projects
          </p>
          <p>
            <span style={numStyle}>50+</span> Built Projects
          </p>
          <p>
            <span style={numStyle}>300+</span> Furnished Houses
          </p>
        </div>
      </div>
      <MainCards />      
    </div>
  );
}
