import { configureStore } from "@reduxjs/toolkit";
import furnitureReducer from "../features/furniture/furnitureSlice";
import albumReducer from "../features/album/albumSlice";
import bigPhotoReducer from "../features/bigPhoto/bigPhotoSlice";
import searchReducer from "../features/search/searchSlice";
import realEstateReducer from "../features/realEstate/realEstateSlice";
import projectsReducer from "../features/projects/projectsSlice";
import architectureReducer from "../features/architecture/architectureSlice";
import projectPhotoReducer from '../features/projectPhoto/projectPhotoSlice'

export const store = configureStore({
  reducer: {
    projects: projectsReducer,
    furniture: furnitureReducer,
    album: albumReducer,
    bigPhoto: bigPhotoReducer,
    search: searchReducer,
    realEstate: realEstateReducer,
    architecture: architectureReducer,
    projectPhoto: projectPhotoReducer
  },
  devTools: process.env.REACT_APP_DEPLOY_MODE !== "production",
});
