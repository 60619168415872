import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import styles from "./mainCards.css";
import { useNavigate, useParams, Link } from "react-router-dom";
import DDALogo from "../../Pics/Logos/DDALogo.png";
import DDFLogo from "../../Pics/Logos/DDFLogo.png";
import DDDLogo from "../../Pics/Logos/DDDLogo.png";
import { picLocations } from "./imgSrc";

export function MainCards({}) {
  const [index, setInedx] = useState(0);
  const [pics, setPics]= useState(picLocations)

  if (index === 9) {
    setInedx((i) => (i = 0));
  }
  useEffect(() => {
    const index = setInterval(() => setInedx((i) => i + 1), 6000);
    return () => clearInterval(index);
  }, []);

  return (
    <div className="mainCardsCont">      
      <Link to="/architecture" className="cardCont">
        <img className="cardLogo" src={DDALogo} />
        <div
          className="cardImg"
          style={{ backgroundImage: `url(${pics.Arch[index]})` }}
        />        
      </Link>
      <Link to="/furniture" className="cardCont">
        <img className="cardLogo" src={DDFLogo} />
        <div
          className="cardImg"
          style={{ backgroundImage: `url(${pics.Fur[index]})` }}
        />        
      </Link>
      <Link to="/realestate" className="cardCont">
        <img className="cardLogo" src={DDDLogo} />
        <div
          className="cardImg"
          style={{ backgroundImage: `url(${pics.Dev[0]})` }}
        />        
      </Link>
    </div>
  );
}
