import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiURL } from '../../App'


export const loadProjects = createAsyncThunk("projects/loadProjects", async () => {
  const data = await fetch(`${apiURL}/projects`);  
  const json = await data.json();
  return json; 
});

export const loadFolders = createAsyncThunk(
  "projects/loadFolders",
  async (folderList) => {
    let list = [];
    for (let i = 0; i < folderList.length; i++) {
      const data = await fetch(`${apiURL}/folder/${folderList[i].id}`)
      const folder = await data.json();
      const cleanFolder = await folder.files;
      let done = {
        coverImage: '',
        planImage: '',
        randomImg: [],
      }; 
      for (let i = 0 ; i < cleanFolder.length ; i++) {
        if (cleanFolder[i].name.includes("cover")) {
          done.coverImage = cleanFolder[i]
        } else if (cleanFolder[i].name.includes("plan")) {
          done.planImage = cleanFolder[i]
        } else {
          done.randomImg.push(cleanFolder[i])
        }
      }      
      //console.log(done);
      list.push(done);
    }
    return list;
  }
);

export const projectsSlice = createSlice({
  name: "projects",
  initialState: {
    projects: [],
    folders: [],
    status: "idle",
  },
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {},
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(loadProjects.pending, (state) => {
        state.status = "loading";
      })
      .addCase(loadProjects.fulfilled, (state, action) => {
        state.status = "done";
        state.projects = action.payload;
      })
      .addCase(loadFolders.fulfilled, (state, action) => {
        state.status = "done2";
        state.folders = action.payload;
      });
  },
});

//export const { increment, decrement, incrementByAmount } = counterSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectProjects = (state) => state.projects.projects;
export const selectFolders = (state) => state.projects.folders;
export const selectStatus = (state) => state.projects.status;

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.

export default projectsSlice.reducer;
