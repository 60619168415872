import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiURL } from "../../App";

export const loadProjectPhoto = createAsyncThunk(
  "projectPhoto/loadProjectPhoto",
  async (link) => {
    //const accToken = await fetch(getToken)
    const data = await `https://drive.google.com/uc?id=${link}&export=download`;
    return data;
  }
);

export const projectPhotoSlice = createSlice({
  name: "projectPhoto",
  initialState: {
    info: [],
    status: "idle",
  },
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    clearInfo: (state, action) => {
      state.info = [];
      state.status = "idle";
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(loadProjectPhoto.pending, (state) => {
        state.status = "loading";
      })
      .addCase(loadProjectPhoto.fulfilled, (state, action) => {
        state.status = "done";
        state.info = action.payload;
      })
      .addCase(loadProjectPhoto.rejected, (state, action) => {
        state.status = "failed";
        console.log("failed");
      });
  },
});

export const selectInfo = (state) => state.projectPhoto.info;
export const selectStatus = (state) => state.projectPhoto.status;
export default projectPhotoSlice.reducer;
export const { clearInfo } = projectPhotoSlice.actions;
