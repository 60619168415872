import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectAlbums, selectStatus1, selectStatus2, loadRealEstate, selectFeed, loadFeed, selectInsta } from './realEstateSlice';
import styles from './realEstate.css';
import { Link, NavLink, Outlet } from 'react-router-dom';
import { Album } from '../album/Album'
import { Feed } from '../feed/Feed'
import Logo from '../../Pics/Logos/DDDLogo.png'
import DDFLoading from '../../Pics/DDFpic.jpg'
import FBPhoto from '../../Pics/socialLogo/FBlogo.png'
import WAPhoto from '../../Pics/socialLogo/WHTSlogo.png'
import IGPhoto from '../../Pics/socialLogo/INSTlogo.png'
import pngFur from '../../Pics/background/furn-2.png'
import ap1 from '../../Pics/archPros/1.jpg'
import ap2 from '../../Pics/archPros/2.JPG'
import ap3 from '../../Pics/archPros/3.jpg'


export function RealEstate() {
  const insta = useSelector(selectInsta)
  const albums = useSelector(selectAlbums);
  const feeds = useSelector(selectFeed)
  const status1 = useSelector(selectStatus1)
  const status2 = useSelector(selectStatus2)
  const dispatch = useDispatch();  
  

  useEffect(() => {    
    dispatch(loadFeed())
  }, [dispatch]);

  useEffect(() => {
    dispatch(loadRealEstate())    
  }, [dispatch]);

  if (status1 === 'loading' || status2 === 'loading') {
    return (    
      <img className='loadingBack' src={DDFLoading} />
    );
  } else if (status1  === 'done' && status2  === 'done') {
    return (
      <div style={{width: '100%', margin: '0 auto', backgroundColor: '#494949'}}  >
        <div className='hero' >
          <div className='backImageReal'>           
          </div>           
          {/*
          <div className='pngsmall'>
            <img  src={pngFur} />
          </div>
          */}          
          <div className='socHero'>
            <a target="_blank" href='https://www.facebook.com/DahabDesignDevelopment' className='tagSocHero'>
              <img src={FBPhoto} />              
            </a>
            {/*<a target="_blank" href='' className='tagSocHero'>
              <img src={IGPhoto} />             
            </a>*/}                        
          </div>    
          {<img className='furnLogo' src={Logo} />}
          <div className='furnText'>            
            <h1>REAL-ESTATE DEVELOPING</h1>                     
          </div>
        </div> 
        {insta ?       
          <div className='sectionFurn'>          
              <Link style={{maxWidth:'100%', boxShadow: '0px 0px 0px -0px #2e2e2e', height:'300px'}} className='albumCont' to={`:${insta.id}`} key={insta.id}>       
              <div className='albumBarCont'>
                <div id='instaBar' className='albumBar'>
                  {insta.photos.data.map((pic) => (                    
                    <img   key={pic.id} loading="lazy" src={pic.images[3].source} />
                  ))}
                </div> 
              </div>                 
            </Link>
          </div>:
          ''
        }         
        <div className='sectionFurn'> 
          <div className='subSectionFurn'>
              <h1>Latest Feed</h1>
              <Feed feed={feeds} />     
          </div>
          <div className='subSectionFurn' >
            <h1>We Build & Construct</h1>
            <h3>Design doesn't mean anything without excution. From Civil, MEP, to Custom Built in Furniture, we turn these ideas of designs into a reality to build your dream home.</h3>
          </div>
          <div className='subSectionFurn' >
            <h1>We Style</h1>
            <h3>In addition to our core Processes, we also help our clients with decoration services to enhance the interior design, such as picking out curtains, carpets, wallpapers and othe accessories imported.</h3>
          </div> 
        </div>  
        {/*
        <div className='sectionFurn'>
          <div style={{flexWrap: 'nowrap'}} className='subSectionFurn' >
            <img className='furnProsIMG' style={{height:'100%'}} src={ap2} />
          </div>  
          <div style={{flexWrap: 'nowrap'}} className='subSectionFurn' >
            <img className='furnProsIMG' style={{height:'100%'}} src={ap1} />
          </div> 
          <div style={{flexWrap: 'nowrap'}} className='subSectionFurn' >
            <img className='furnProsIMG' style={{height:'100%'}} src={ap3} />
          </div>                   
        </div>        
        */}
        <h1 className='ourWork' >Real-Estate Projects</h1>              
        <div  className='albums'>            
          {albums.map((album) => ( 
            <div className='mainAlbum' key={album.id} >
              <Link className='albumName' to={`:${album.id}`} >{album.name}</Link>         
              <Link className='albumCont' to={`:${album.id}`} >                
                {/*<img className='albumCover' src={album.photos.data[0].images[3].source} />*/}
                <div className='albumBarCont'>
                  <div className='albumBar'>
                    {album.photos.data.map((pic) => (                    
                      <img key={pic.id} loading="lazy" src={pic.images[3].source} />
                    ))}
                    
                  </div> 
                </div>                 
              </Link>
              <div className='borderOnly1'>
              </div>
            </div>                             
          ))}              
        </div>
      </div>
    );
  }
  
}
