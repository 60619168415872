import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  selectInfo,
  selectStatus,
  loadProjectPhoto,
  clearInfo,
} from "./projectPhotoSlice";
import { useNavigate, useParams, useLocation } from "react-router-dom";


export function ProjectPhoto({}) {
  const info = useSelector(selectInfo);
  const status = useSelector(selectStatus);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { link } = useParams();
  const num = link.replace(":","");
  const location = useLocation();
  const pagePath = location.pathname;

  useEffect(() => {    
    dispatch(loadProjectPhoto(num));    
  }, [dispatch]);

  const closeImg = () => {
    dispatch(clearInfo());
    navigate(-1);
  };

  if (status === "idle") {
    return <div></div>;
  } else if (status === "loading") {
    return (
      <div onClick={closeImg} className="blackOut">
        <h2>Loading</h2>
      </div>
    );
  } else if (status === "done") {
    return (
      <div onClick={closeImg} className="blackOut">
        <img className="thePic" src={info} />        
      </div>
    );
  }
}
