import React from "react";
import logo from "./logo.svg";
import "./App.css";
import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import { Home } from "./features/home/Home";
import { Projects } from "./features/projects/Projects";
import { RealEstate } from "./features/realEstate/RealEstate";
import { Furniture } from "./features/furniture/Furniture";
import { Album } from "./features/album/Album";
import { BigPhoto } from "./features/bigPhoto/BigPhoto";
import { Root } from "./features/root/Root";
import { About } from "./features/about/About";
import { Search } from "./features/search/Search";
import { SearchPhoto } from "./features/searchPhoto/SearchPhoto";
import { Architecture } from "./features/architecture/Architecture";
import { disableReactDevTools } from "@fvilers/disable-react-devtools";
import { ProjectPhoto } from './features/projectPhoto/ProjectPhoto'

if (process.env.REACT_APP_DEPLOY_MODE === "production") {
  disableReactDevTools();
}

export const apiURL = '/api'

const router = createBrowserRouter(
  createRoutesFromElements(
    /* Wrap this Root Route to create Router here */
    <Route path="/" element={<Root />}>
      <Route path="home" element={<Home />} />
      <Route path="search" element={<Search />}>
        <Route path=":id3" element={<SearchPhoto />} />
      </Route>
      <Route path="about" element={<About />} />
      <Route path="projects" element={<Projects />} >
        <Route path=":link" element={<ProjectPhoto />} />
      </Route>
      <Route path="realestate" element={<RealEstate />} />
      <Route path="realestate/:id" element={<Album />}>
        <Route path=":id2" element={<BigPhoto />} />
      </Route>
      <Route path="furniture" element={<Furniture />} />
      <Route path="furniture/:id" element={<Album />}>
        <Route path=":id2" element={<BigPhoto />} />
      </Route>
      <Route path="architecture" element={<Architecture />} />
      <Route path="architecture/:id" element={<Album />}>
        <Route path=":id2" element={<BigPhoto />} />
      </Route>
    </Route>
  )
);

function App() {
  return (
    <div className="App">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
