import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styles from './about.css';
import { Link, NavLink, Outlet } from 'react-router-dom';
import his1 from '../../Pics/history/1.jpg'
import his2 from '../../Pics/history/2.jpg'


export function About({}) {   
  const [state,setState] = useState('what')
  const handleAbout = ({ state }) => setState('about');
  const handleWhat = ({ state }) => setState('what');
  const handleWhere = ({ state }) => setState('where');

  const activeStyleHead = {
    textDecoration: 'none',
    fontWeight: '600',
    color: '#d32026 ',
    letterSpacing: '2px',
    padding: '10px' 
  }; 

  const nonActiveStyleHead = {
    textDecoration: 'none',
    fontWeight: '500',
    color: '#071a52',
    letterSpacing: '0px'
  };

  const activeStyleBody = {
    display: 'block'
  }; 

  const nonActiveStyleBody = {
    display: 'none'
  };



  return (
    <div className='aboutCont' >
      <div className='aboutBox'>
        <div className='aboutHead'>
          <h1 style={state === 'about'? activeStyleHead : nonActiveStyleHead } onClick={handleAbout} >ABOUT-US</h1>
          <h1 style={state === 'what'? activeStyleHead : nonActiveStyleHead } onClick={handleWhat} >WHAT WE DO</h1>
          <h1 style={state === 'where'? activeStyleHead : nonActiveStyleHead } onClick={handleWhere} >DD History</h1>
        </div>
        <div style={state === 'about'? activeStyleBody : nonActiveStyleBody } className='aboutBody'>
          <div className='aboutUS' >
            <p  >
            DAHAB DESIGN is a Full-Service Firm specialized in Real-Estate Architectural-Designs & Furniture 
            <br/>
            <br/>
            DAHAB DESIGN is the mother company to DD Architects & Consultants, and DD Furniture based in Egypt.
            <br/>
            Established in 1992, since then, DD has been working on over 50 design projects, locally in Alexandria,Cairo and Sharm El Sheikh
            <br/>
            & Internationally in several Countries inclucing KSA, Dubai, Bahrin & Qatar.
            <br/>
            </p>          
            <h1 className='whyUS'>Why us?</h1>
            <p>
            DD always aims for better quality design, while continuously seeking ways to reduce cost.
            <br/>
            DD team consists of a highly Qualified and passionate team, all have their own uique style & prespective, providing you the highest standards of quality design.
            <br/>
            Whether you are into Traditional Classic style or are into Modern style
            </p> 
          </div>
        </div>
        <div style={state === 'what'? activeStyleBody : nonActiveStyleBody } className='aboutBody'>
          <div className='whatWeDoCont' >
            <div className='whatWeDo' >
              <h1>1</h1>
              <h2>We Consult & Renovate</h2>
              <p>
                DD provides you with free consultation regarding your design preference, thus everything will be tailored based unon you needs                
              </p>
              <p>
              Aside from our interior design services,we also do general contracting services renovating work reauested by the clients
              </p>
            </div>
            <div className='whatWeDo' >
              <h1>2</h1>
              <h2>We Layout & Design</h2>
              <p>
                Conceptualizing 3D Designs to turn whats on paper into reality is the sole purpose of DD interior office.Providing the client with real life renders experiences.
                <br/>
                <br/>
                100+ projects designed
              </p>              
            </div>
            <div className='whatWeDo' >
              <h1>3</h1>
              <h2>We Build & Construct</h2>
              <p>
                Design doesn't mean anything without excution.
                <br/>
                From Civil, MEP, to Custom Built in Furniture, we turn these ideas of designs into a reality to build your dream home.
                <br/>
                <br/>
                50+ Projects built (Villa, Houses, Stores, Buildings & resorts.)
              </p>              
            </div>
            <div className='whatWeDo' >
              <h1>4</h1>
              <h2>We Furniture</h2>
              <p>
                At DDF We have our own designs, and signature pieces, but also customise furniture according to client's/interior designers colleaguse requests. All at hight quality standards to meet our clients' expectations
                <br/>
                <br/>
                300+ Houses Furnished
              </p>              
            </div>
            <div className='whatWeDo' >
              <h1>5</h1>
              <h2>We Style</h2>
              <p>
                In addition to our core Processes, we also help our clients with decoration services to enhance the interior design, such as picking out curtains, carpets, wallpapers and othe accessories imported.
              </p>              
            </div>
          </div>
        </div>
        <div style={state === 'where'? activeStyleBody : nonActiveStyleBody } className='aboutBody'>
          <div className='hisCont'>
            <img className='hisImg' src={his1} />
            <img className='hisImg' src={his2} />
          </div>
        </div>
      </div>
    </div>
  )
  
}
