import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
//import { selectInfo, selectStatus, loadBigPhoto, clearInfo } from './footerSlice';
import styles from './footer.css';
import { useNavigate, useParams } from 'react-router-dom';
import { Album } from '../album/Album'
import FBPhoto from '../../Pics/socialLogo/FBlogo.png'
import WAPhoto from '../../Pics/socialLogo/WHTSlogo.png'
import IGPhoto from '../../Pics/socialLogo/INSTlogo.png'
import Mailphoto from '../../Pics/socialLogo/Maillogo.png'  
import callUs from '../../Pics/socialLogo/CallUs.png'
import ArDown from '../../Pics/socialLogo/ArDown.png'
import DDLogo from '../../Pics/Logos/DDLogo.png'


export function Footer(props) {  
  const { showContact, changeContactStatus} = props   
  const navigate = useNavigate();

  const showStyle = {
    bottom: '0px' 
  }; 

  const hideStyle = {
    bottom: '-211px' 
  }; 

  const pickStyle = () => {
    if (showContact === true) {
      return showStyle;
    } else if (showContact === false) {
      return hideStyle
    } 
  }  
  
  const back = () => {    
    navigate(-1)
  }

  const forward = () => {    
    navigate(+1)
  }  
 

  return (
    <div style={pickStyle()} className='mainFooter'>
      <div className='smallFooter'>
        <a onClick={back} >- BACK -</a>
        <a onClick={changeContactStatus} >{!showContact? '' : (<img style={{ width:'30px', filter: 'invert(1)' }} src={ArDown} />)}</a>
        <a onClick={forward} >- NEXT -</a>
      </div>
      <div className='BigFooter'>
        <div className='contactTab'> 
          <img className='DDLogo' src={DDLogo} />        
          
        </div>
        <div id='contactTabCenter' className='contactTab'>          
          <div id='tagCenter' className='tag'>
            <img style={{filter: 'invert(1)'}} src={callUs} />
            <p>DD Company: +20 3 5223055 / +20 109 7779776</p>
          </div>          
          <div id='tagCenter' className='tag'>
            <img src={WAPhoto} />
            <p>WhatsApp: +20 109 9925577 / +20 109 9925578</p>
          </div>
          <a href='' id='tagCenter' className='tag'>
            <img src={Mailphoto} />
            <p>E-Mail: info@dahabdesign.com</p>
          </a>
        </div>
        <div className='contactTab'>          
          <a target="_blank" href='https://www.facebook.com/DahabDesignDevelopment/'  className='tag'>
            <img src={FBPhoto} />
            <p>RealEstate</p>
          </a>
          <a target="_blank" href='https://www.facebook.com/DahabDesignArchitect/' className='tag'>
            <img src={FBPhoto} />
            <p>Architecture</p>
          </a>
          <a target="_blank" href='https://www.facebook.com/DahabDesignFurniture/' className='tag'>
            <img src={FBPhoto} />
            <p>Furniture</p>
          </a>
          <a target="_blank" href='https://www.instagram.com/dahabdesignfurniture/' className='tag'>
            <img src={IGPhoto} />
            <p>Instagram</p>
          </a>
        </div>   
        
      </div>
    </div>
  )
  
}
