import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { apiURL } from '../../App'


export const loadPhotos = createAsyncThunk(
  'search/loadPhotos',
  async () => {      
    const data = await fetch(`${apiURL}/loadsearch`)    
    const json = await data.json();
    return json;    
  }
);

export const callNext = createAsyncThunk(
  'search/callNext',
  async () => {      
    const data = await fetch(`${apiURL}/nextsearch`)    
    const json = await data.json();
    return json;    
  }
);


export const searchSlice = createSlice({
  name: 'search',
  initialState: {   
    pics: [],
    results: [],
    cursorsFB: 'idle',    
    status: 'idle',
  },
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: { 
    filterPics: (state,action) => { 
      if (action.payload === '') {
        state.results = []
      } else {
        state.results = state.pics.filter(pic => {
          try{
            return pic.name.toLowerCase().includes(action.payload.toLowerCase())
          } catch(e){
            
          }
        })
      }     
    }    
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(loadPhotos.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(loadPhotos.fulfilled, (state, action) => {        
        state.status = 'done';
        state.pics = action.payload; 
        state.cursorsFB = 1
          
      })
      .addCase(loadPhotos.rejected, (state, action) => {
        state.status = 'failed1';
        console.log('failed1')
      })
      .addCase(callNext.pending, (state) => {        
      })
      .addCase(callNext.fulfilled, (state, action) => {             
          state.pics = state.pics.concat(action.payload.photoslist)
          state.cursorsFB = action.payload.helper
      })
      .addCase(callNext.rejected, (state, action) => {
        //state.status = 'failed2';
        console.log('failed2')
      });
  },
});

export const selectPics = (state) => state.search.pics;
export const selectStatus = (state) => state.search.status;
export const selectResults = (state) => state.search.results;
export const selectCursorsFB =  (state) => state.search.cursorsFB
export const { filterPics } = searchSlice.actions
export default searchSlice.reducer;

// export const { increment, decrement, incrementByAmount } = counterSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`


// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.



