import React, { useState, useEffect, lazy } from "react";
import { useSelector, useDispatch } from "react-redux";
import styles from "./project.css";
import { Link } from "react-router-dom";
import ArDown from "../../Pics/socialLogo/ArDown2.png";

export function Project({ project, folder, status }) {
  const [showChild, setShowChild] = useState(false);

  const changeshowChild = () => {
    setShowChild(!showChild);
  };

  const dispatch = useDispatch();
  useEffect(() => {
    if (status === "done2") {
    }
  }, [dispatch]);

  return (
    <div className="projectCont">
      <div className="projectVis">
        <h2>{project.name}</h2>
        <Link to={`:${folder.coverImage.id}`} className="projectCov">
          <img
            style={{ width: "100%" }}
            src={folder.coverImage.webContentLink}
          />
        </Link>
        <img className="projectPlan" src={folder.planImage.webContentLink} />
        <p>{project.description}</p>
      </div>
      <div
        className="projectHid"
        style={showChild ? { display: "flex" } : { display: "none" }}
      >
        {folder.randomImg.map((img) => (
          <Link to={`:${img.id}`} key={img.id} className="projImg">
            <img loading="lazy" src={img.webContentLink} />
          </Link>
        ))}
      </div>
      <div onClick={changeshowChild} className="projectButton">
        <img
          style={
            showChild
              ? { transform: "rotate(180deg)" }
              : { transform: "rotate(0deg)" }
          }
          src={ArDown}
        />
      </div>
    </div>
  );
}
