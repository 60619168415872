import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiURL } from '../../App'

export const loadAlbum = createAsyncThunk(
  "album/loadAlbum",
  async (loadAlbumParams) => {
    //const accToken = await fetch(getToken)
    const data = await fetch(
      `${apiURL}/album?albumID=${loadAlbumParams.id}&path=${loadAlbumParams.curPage}`
    );
    const json = await data.json();
    return json;
  }
);

export const callNext = createAsyncThunk("album/callNext", async (params) => {
  const data = await fetch(
    `${apiURL}/albumnext?pageID=${params.id}&next=${params.link}&path=${params.curPage}`
  );
  const json = await data.json();
  return json;
});

export const albumSlice = createSlice({
  name: "album",
  initialState: {
    pics: [],
    albumName: [],
    status: "idle",
  },
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {},
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(loadAlbum.pending, (state) => {
        state.status = "loading";
      })
      .addCase(loadAlbum.fulfilled, (state, action) => {
        state.status = "done";
        try {
          state.pics = action.payload.photos;
          state.albumName = action.payload;
        } catch (e) {
          state.pics = [];
          console.log("somthing went wrong");
        }
      })
      .addCase(loadAlbum.rejected, (state, action) => {
        state.status = "failed";
        console.log("failed");
      })
      .addCase(callNext.pending, (state) => {
        state.status = "done";
      })
      .addCase(callNext.fulfilled, (state, action) => {
        state.status = "done";
        action.payload.data.forEach((photo) => state.pics.data.push(photo));
        try {
          state.pics.paging.cursors.after = action.payload.paging.cursors.after;
        } catch (e) {
          state.pics.paging.cursors.after = "noMore";
        }
      })
      .addCase(callNext.rejected, (state, action) => {
        state.status = "failed";
        console.log("failed");
      });
  },
});

export const selectPics = (state) => state.album.pics;
export const selectStatus = (state) => state.album.status;
export const selectAlbumName = (state) => state.album.albumName;
export default albumSlice.reducer;

// export const { increment, decrement, incrementByAmount } = counterSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
