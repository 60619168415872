import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { apiURL } from '../../App'

export const loadFurniture = createAsyncThunk(
  'furniture/loadFurniture',
  async () => {      
    //const accToken = await fetch(getToken)
    const data = await fetch(`${apiURL}/furnalbums`)    
    const json = await data.json();
    return json;
  }
);


export const loadFeed = createAsyncThunk(
  'furniture/loadFeed',
  async () => {      
    //const accToken = await fetch(getToken)
    const data = await fetch(`${apiURL}/furnfeed`)    
    const json = await data.json();
    return json;
  }
);


export const furnitureSlice = createSlice({
  name: 'furniture',
  initialState: {   
    albums: [],
    feed: [],
    insta: [],
    status1: 'idle',
    status2: 'idle'    
  },
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {    
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(loadFurniture.pending, (state) => {
        state.status1 = 'loading';
      })
      .addCase(loadFurniture.fulfilled, (state, action) => {
        state.status1 = 'done';
        state.albums = action.payload.albums;
        state.insta = action.payload.insta
        
      })
      .addCase(loadFurniture.rejected, (state, action) => {
        state.status1 = 'failed';
        console.log('failed')
      })
      .addCase(loadFeed.pending, (state) => {
        state.status2 = 'loading';
      })
      .addCase(loadFeed.fulfilled, (state, action) => {
        state.status2 = 'done';
        state.feed = action.payload;        
      })
      .addCase(loadFeed.rejected, (state, action) => {
        state.status2 = 'failed';
        console.log('failed')
      })
  },
});

export const selectAlbums = (state) => state.furniture.albums;
export const selectFeed = (state) => state.furniture.feed;
export const selectInsta = (state) => state.furniture.insta[0];
export const selectStatus1 = (state) => state.furniture.status1;
export const selectStatus2 = (state) => state.furniture.status2;
export default furnitureSlice.reducer;

// export const { increment, decrement, incrementByAmount } = counterSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`


// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.



