import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectAlbums, selectStatus1, selectStatus2, loadFurniture, selectFeed, loadFeed, selectInsta } from './furnitureSlice';
import styles from './furniture.css';
import { Link, NavLink, Outlet } from 'react-router-dom';
import { Album } from '../album/Album'
import { Feed } from '../feed/Feed'
import Logo from '../../Pics/Logos/DDFLogo.png'
import DDFLoading from '../../Pics/DDFpic.jpg'
import FBPhoto from '../../Pics/socialLogo/FBlogo.png'
import WAPhoto from '../../Pics/socialLogo/WHTSlogo.png'
import IGPhoto from '../../Pics/socialLogo/INSTlogo.png'
import pngFur from '../../Pics/background/furn-2.png'
import fp1 from '../../Pics/furnInPros/1.jpg'
import fp2 from '../../Pics/furnInPros/2.jpg'
import fp3 from '../../Pics/furnInPros/3.jpg'
import fp4 from '../../Pics/furnInPros/4.jpg'

export function Furniture() {
  const insta = useSelector(selectInsta)
  const albums = useSelector(selectAlbums);
  const feeds = useSelector(selectFeed)
  const status1 = useSelector(selectStatus1)
  const status2 = useSelector(selectStatus2)
  const dispatch = useDispatch();  
  

  useEffect(() => {    
    dispatch(loadFeed())
  }, [dispatch]);

  useEffect(() => {
    dispatch(loadFurniture())    
  }, [dispatch]);

  if (status1 === 'loading' || status2 === 'loading') {
    return (    
      <img className='loadingBack' src={DDFLoading} />
    );
  } else if (status1  === 'done' && status2  === 'done') {
    return (
      <div style={{width: '100%', margin: '0 auto', backgroundColor: '#494949'}}  >
        <div className='hero' >
          <div className='backImage'>           
          </div>           
          {/*
          <div className='pngsmall'>
            <img  src={pngFur} />
          </div>
          */}          
          <div className='socHero'>
            <a target="_blank" href='https://www.facebook.com/DahabDesignFurniture' className='tagSocHero'>
              <img src={FBPhoto} />              
            </a>
            <a target="_blank" href='https://www.instagram.com/dahabdesignfurniture/' className='tagSocHero'>
              <img src={IGPhoto} />             
            </a>                        
          </div>    
          {<img className='furnLogo' src={Logo} />}
          <div className='furnText'>            
            <h1>FURNITURE STORE ,WALL DECOR, HOME ACCESSORIES & CUSTOM MADE DESIGNS</h1>                     
          </div>
        </div>        
        <div className='sectionFurn'>
          <Link style={{maxWidth:'100%', boxShadow: '0px 0px 0px -0px #2e2e2e', height:'300px'}} className='albumCont' to={`:${insta.id}`} key={insta.id}>       
            <div className='albumBarCont'>
              <div id='instaBar' className='albumBar'>
                {insta.photos.data.map((pic) => (                    
                  <img   key={pic.id} loading="lazy" src={pic.images[3].source} />
                ))}
              </div> 
            </div>                 
          </Link>
        </div>         
        <div className='sectionFurn'> 
          <div className='subSectionFurn'>
              <h1>Latest Feed</h1>
              <Feed feed={feeds} />     
          </div>
          <div className='subSectionFurn' >
            <h1>We Furniture</h1>
            <h3>At DDF We have our own designs, and signature pieces, but also customise furniture according to client's/interior designers colleaguse requests. All at hight quality standards to meet our clients' expectations</h3>
          </div>
          <div className='subSectionFurn' >
            <h1>We Customize</h1>
            <h3>At DDF we design every furnitur to fit perfctly in the design. We pay extra attention to details, and quality to produce a unique piece of art for your home</h3>
          </div>          
          <div className='subSectionFurn'>
            <h1>Visit Us</h1>
            <iframe className='map'  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1705.8304101226317!2d29.94833218435308!3d31.23012508696939!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14f5c4e7e682b923%3A0xc663412ff8ce4192!2sDahab%20Design%20Furniture!5e0!3m2!1sen!2seg!4v1677777521326!5m2!1sen!2seg" width="600" height="450" allowFullScreen="" loading="lazy" ></iframe>
          </div>  
        </div>  
        {/*
        <div className='sectionFurn'>
          <div style={{flexWrap: 'nowrap'}} className='subSectionFurn' >
            <img className='furnProsIMG' style={{height:'100%'}} src={fp1} />
          </div>  
          <div style={{flexWrap: 'nowrap'}} className='subSectionFurn' >
            <img className='furnProsIMG' style={{height:'100%'}} src={fp2} />
          </div> 
          <div style={{flexWrap: 'nowrap'}} className='subSectionFurn' >
            <img className='furnProsIMG' style={{height:'100%'}} src={fp3} />
          </div> 
          <div style={{flexWrap: 'nowrap'}} className='subSectionFurn' >
            <img className='furnProsIMG' style={{height:'100%'}} src={fp4} />
          </div>          
        </div>        
        */}
        <h1 className='ourWork' >Categories</h1>              
        <div  className='albums'>            
          {albums.map((album) => ( 
            <div className='mainAlbum' key={album.id} >
              <Link className='albumName' to={`:${album.id}`} >{album.name}</Link>         
              <Link className='albumCont' to={`:${album.id}`} >                
                {/*<img className='albumCover' src={album.photos.data[0].images[3].source} />*/}
                <div className='albumBarCont'>
                  <div className='albumBar'>
                    {album.photos.data.map((pic) => (                    
                      <img key={pic.id} loading="lazy" src={pic.images[3].source} />
                    ))}
                    
                  </div> 
                </div>                 
              </Link>
              <div className='borderOnly1'>
              </div>
            </div>                             
          ))}              
        </div>
      </div>
    );
  }
  
}
