import f1 from './Imgs/Funr/f1.jpg'
import f2 from './Imgs/Funr/f2.jpg'
import f3 from './Imgs/Funr/f3.jpg'
import f4 from './Imgs/Funr/f4.jpg'
import f5 from './Imgs/Funr/f5.jpg'
import f6 from './Imgs/Funr/f6.jpg'
import f7 from './Imgs/Funr/f7.jpg'
import f8 from './Imgs/Funr/f8.jpg'
import f9 from './Imgs/Funr/f9.jpg'
import f10 from './Imgs/Funr/f10.jpg'

import a1 from './Imgs/Arch/a1.jpg'
import a2 from './Imgs/Arch/a2.jpg'
import a3 from './Imgs/Arch/a3.jpg'
import a4 from './Imgs/Arch/a4.jpg'
import a5 from './Imgs/Arch/a5.jpg'
import a6 from './Imgs/Arch/a6.jpg'
import a7 from './Imgs/Arch/a7.jpg'
import a8 from './Imgs/Arch/a8.jpg'
import a9 from './Imgs/Arch/a9.jpg'
import a10 from './Imgs/Arch/a10.jpg'

import d1 from '../../Pics/background/r1.jpg'




export const picLocations = {
    Dev:[ d1 ],
    Arch:[ a1, a2, a3, a4, a5, a6, a7, a8, a9, a10  ],
    Fur:[ f1, f2, f3, f4, f5, f6, f7, f8, f9, f10 ],

}