import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectProjects, selectStatus, loadProjects, loadFolders, selectFolders } from './projectsSlice';
import styles from './projects.css';
import { Link, NavLink, Outlet } from 'react-router-dom';
import { MainCards } from '../mainCards/MainCards'
import { Project } from '../project/Project'
import DDFLoading from '../../Pics/DDFpic.jpg'





export function Projects() {
  const projects = useSelector(selectProjects);
  const folders = useSelector(selectFolders);
  const status = useSelector(selectStatus);
  const dispatch = useDispatch();  

  useEffect(() => {
    dispatch(loadProjects())
  },[dispatch])

  useEffect(() => {
    if (status === 'done') {
      dispatch(loadFolders(projects))
    }    
  })

  return (         
      <div className='homeBody'>  
      <Outlet />     
        <h1 className='latestProjects' >Projects Designed & Furnished by DAHAB DESIGN</h1> 
        <div>
          {
            status === 'done2'?            
            projects.map((project,index) => (                    
              <Project folder={folders[index]} key={project.id} project={project} status={status} />
            )):
            <img className='loadingBack' src={DDFLoading} />
          }
        </div>
      </div>        
  );
}
