import React, { useState, useEffect, useRef  } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectPics, selectStatus, loadPhotos, callNext, selectCursorsFB, selectResults, filterPics } from './searchSlice';
import styles from './search.css';
import { useParams, Link, Outlet } from 'react-router-dom';
import DDFLoading from '../../Pics/DDFpic.jpg'


export function Search({}) {
  const [name, setName] = useState('')
  const pics = useSelector(selectPics);
  const status = useSelector(selectStatus); 
  const cursorsFB = useSelector(selectCursorsFB);
  const results = useSelector(selectResults)
  const dispatch = useDispatch();

  const next = () => {   
    //console.log(cursorsFB)
    dispatch(callNext())      
  } 
  
  const handleSearch = ({ target }) => setName(target.value);
  
  useEffect(() => {
    setTimeout(() => {
      if (pics.length > 0 ) {
        return
      } else {
        dispatch(loadPhotos());
      }  
    }, 200);    
  }, [dispatch]);
  
  useEffect(() => { 
    setTimeout(() => {
      if (cursorsFB === 'idle' || cursorsFB === 0){
        return
      } else {
        dispatch(callNext())
      }      
    },500);      
  }); 

  useEffect(() => {    
    dispatch(filterPics(name))
  },[name])

  /*
  function debounce(func, timeout = 300){
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => { func.apply(this, args); }, timeout);
    };
  }
  function saveInput(){
    console.log('Saving data');
  }
  const processChange = debounce(() => saveInput());
  */
  
  

  if (status === 'done') {
    return (
      <div>
        <Outlet/>        
        <div className='searchBarCont'>          
          <div className="form-group mb-4">
            <input id="exampleFormControlInput1" value={name} onChange={handleSearch}  placeholder="What're you searching for?" className="form-control form-control-underlined" />
          </div>
        </div> 
        {
          <div className='album'>
          {results.map((photo) => (                    
            <Link className='card' to={`:${photo.id}`} key={photo.id}>
              <img loading="lazy" className='smallPhoto' src={photo.source} />                                  
            </Link>                
          ))}
          </div>
        }         
      </div>      
    )
  } else {
    return (
      <img className='loadingBack' src={DDFLoading} />
    )
    
  }
  
}
