import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styles from './root.css';
import { useNavigate, useParams, NavLink, Outlet, redirect, useLocation } from 'react-router-dom';
import { Album } from '../album/Album'
import Logo from '../../Pics/Logos/DDENGLOGO2.png'
import cover from '../../Pics/background/bkw2.jpg'
import { Footer } from '../footer/Footer'


export function Root({}) { 
  const navigate = useNavigate(); 
  const [ showContact , setShowContact] = useState(false);
  const currentLocation = useLocation()

  //style for navlink
  let activeStyle1 = {
    //backgroundColor: "#d32026",
    color: '#d32026',
    fontWeight: 'bold',
    letterSpacing: '3px',    
  }; 
  
  //maneging the bottom margin
  const moveDown = {
    marginBottom: '50px' 
  }; 

  const moveUp = {
    marginBottom: '260px' 
  }; 

  const pickStyle = () => {
    if (showContact === true) {
      return moveUp;
    } else if (showContact === false) {
      return moveDown
    } 
  }

  //opening and closing contact tab
  const changeContactStatus = () => {
    setShowContact(!showContact)
  }

  const trueContactStatus = () => {
    setShowContact(true)
  }  
  
  useEffect(() => {
    if (currentLocation.pathname === '/') {
      navigate('/home')
    }    
  },[]);



  return(
    <div > 
      {/*
        <div style={{backgroundImage: `url(${cover})`}} className='heroCoverDD'>
      </div>
      */}     
      <div className='fixedNav'>
        <div onClick={() => {navigate('/home')}} className='logo' > 
          <img  src={Logo} />
        </div>
        <div className='links'>
          <nav>
            <NavLink style={({ isActive }) => isActive ? activeStyle1 : undefined } className='singleLink' to="/home">HOME</NavLink>
            <NavLink style={({ isActive }) => isActive ? activeStyle1 : undefined } className='singleLink' to="/about">ABOUT</NavLink>
            <NavLink style={({ isActive }) => isActive ? activeStyle1 : undefined } className='singleLink' to="/projects">PROJECTS</NavLink>
            <NavLink style={({ isActive }) => isActive ? activeStyle1 : undefined } className='singleLink' to="/architecture">ARCHITECTURE</NavLink>
            <NavLink style={({ isActive }) => isActive ? activeStyle1 : undefined } className='singleLink' to="/furniture">FURNITURE</NavLink>
            <NavLink style={({ isActive }) => isActive ? activeStyle1 : undefined } className='singleLink' to="/realestate">REAL-ESTATE</NavLink>
            <NavLink style={({ isActive }) => isActive ? activeStyle1 : undefined } className='singleLink' to="/search">SEARCH</NavLink>
            <a onClick={changeContactStatus} className='singleLink' to="/home">CONTACT</a>                          
          </nav>
        </div>

      </div>
      {/*<img src={Logo} className='heroCoverDD' />*/}
      <div className='outLet' style={pickStyle()}>
        <Outlet/>
      </div>      
      <Footer changeContactStatus={changeContactStatus} showContact={showContact} />
    </div>
  )

 
  
}


/*
            <NavLink style={({ isActive }) => isActive ? activeStyle : undefined } className='singleLink' to="/construction">CONSTRUCTION</NavLink> 
            <NavLink style={({ isActive }) => isActive ? activeStyle : undefined } className='singleLink' to="/architecture">ARCHITECTURE</NavLink>    
            <NavLink style={({ isActive }) => isActive ? activeStyle : undefined } className='singleLink' to="/furniture">FURNITURE</NavLink>
*/            
