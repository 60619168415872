import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectInfo, selectStatus, loadBigPhoto, clearInfo } from './searchPhotoSlice';
import { useNavigate, useParams } from 'react-router-dom';



export function SearchPhoto({}) {   
  const info = useSelector(selectInfo);
  const status = useSelector(selectStatus);
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const { id3 } = useParams()
  const num = id3.replace(":","");  

  useEffect(() => {
    dispatch(loadBigPhoto(num));
  }, [dispatch]); 
 

  const closeImg = () => {
    dispatch(clearInfo());
    navigate(-1)
  }
  
  if (status === 'idle') {
    return (
      <div></div>
    )
  } else if (status === 'loading') {
    return (
      <div onClick={closeImg} className='blackOut'>
        <h2 >{num}</h2>
      </div>
    )
  } else if (status === 'done') {
    return (
      <div onClick={closeImg} className='blackOut'>
        <img className='thePic' src={info.images[0].source} />
        <div className='strip' >
          <p>{info.name}</p>
        </div>              
      </div>
    )
  }

  
  
}
