import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  selectInfo,
  selectStatus,
  loadBigPhoto,
  clearInfo,
} from "./bigPhotoSlice";
import styles from "./bigPhoto.css";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { Album } from "../album/Album";

export function BigPhoto({}) {
  const info = useSelector(selectInfo);
  const status = useSelector(selectStatus);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id2 } = useParams();
  const num = id2.replace(":", "");
  const location = useLocation();
  const pagePath = location.pathname;

  useEffect(() => {
    let loadPhotoParams = {
      id: num,
      curPage: pagePath,
    };
    dispatch(loadBigPhoto(loadPhotoParams));
  }, [dispatch]);

  const closeImg = () => {
    dispatch(clearInfo());
    navigate(-1);
  };

  if (status === "idle") {
    return <div></div>;
  } else if (status === "loading") {
    return (
      <div onClick={closeImg} className="blackOut">
        <h2>{num}</h2>
      </div>
    );
  } else if (status === "done") {
    return (
      <div onClick={closeImg} className="blackOut">
        <img className="thePic" src={info.images[0].source} />
        <div className="strip">
          <p>{info.name}</p>
        </div>
      </div>
    );
  }
}
