import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  selectPics,
  selectStatus,
  selectAlbumName,
  loadAlbum,
  callNext,
} from "./albumSlice";
import styles from "./album.css";
import { useParams, Link, Outlet, useLocation } from "react-router-dom";

export function Album({}) {
  const pics = useSelector(selectPics);
  const status = useSelector(selectStatus);
  const albumName = useSelector(selectAlbumName);
  const dispatch = useDispatch();
  const location = useLocation();
  const pagePath = location.pathname;
  //const [incrementAmount, setIncrementAmount] = useState('2');

  const { id } = useParams();
  const num = id.replace(":", "");

  useEffect(() => {
    let loadAlbumParams = {
      id: num,
      curPage: pagePath,
    };
    dispatch(loadAlbum(loadAlbumParams));
  }, [dispatch]);

  const next = () => {
    if (pics.paging.cursors.after === "noMore") {
      console.log("no more to load");
    } else if (pics.paging.cursors.after) {
      let param = {
        id: albumName.id,
        link: pics.paging.cursors.after,
        curPage: pagePath,
      };
      dispatch(callNext(param));
    } else {
      console.log("no more");
    }
  };

  if (status === "loading") {
    return <h1>loading</h1>;
  } else if (status === "done") {
    return (
      <div>
        <Outlet />
        <div>
          {albumName.name === "Instagram Photos" ? (
            <h1 className="albumTitle">Random Collection</h1>
          ) : (
            <h1 className="albumTitle">{albumName.name}</h1>
          )}
        </div>
        <div className="album">
          {pics.data.map((photo) => (
            <Link className="card" to={`:${photo.id}`} key={photo.id}>
              <img
                loading="lazy"
                className="smallPhoto"
                src={photo.images[0].source}
              />
            </Link>
          ))}
        </div>
        {pics.paging.cursors.after !== "noMore" ? (
          <h1 className="more" onClick={next}>
            - More -
          </h1>
        ) : (
          ""
        )}
      </div>
    );
  }
}
