import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styles from './feed.css';
import { Link, NavLink, Outlet } from 'react-router-dom';
import { Album } from '../album/Album'


export function Feed({feed}) {  

    
  //const [singleFeed, setSingleFeed] = useState(feed[i]);
  const [index, setInedx] = useState(0);
  
  if( index === feed.length - 1) {
    setInedx(i => i = 0)
    console.log('reset')
  }
  useEffect(() => {
    const index = setInterval(() => setInedx(i => i+1), 10000);
   return () => clearInterval(index);
  },[]);
  
  

  return (
    <div className='feedCont'> 
      {feed[index].full_picture ?
      <img className='feedImg' src={feed[index].full_picture} />:   
      <h1 >{feed[index].message}</h1> 
      }        
    </div>
  )
  
}
